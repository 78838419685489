<template>
  <div>
    <div class="page-title pageheading" style="height: 50px;">
      <h3 class="titlename">
        <i class="fas fa-user"></i
        ><span class="ml-3">Care Coordinator Dashboard</span>
      </h3>
    </div>

    <div>
      <datatable
        :header="headers"
        :item="desserts"
        :showAction="true"
        :showSearch="true"
      ></datatable>
    </div>
  </div>
</template>
<script>
import datatable from "../../components/datatable.vue";
export default {
  components: {
    datatable,
  },
  data() {
    return {
      search: "",
      showChangePass: true,
      clientid: "",
      desserts: [],
    };
  },
  computed: {
    headers() {
      return [
        { text: "Subject", align: "start", sortable: true, value: "subject" },
        { text: "Comments", value: "comment" },
        { text: "Status", value: "status" },
        { text: "Date", value: "date" },
        { text: "Created by", value: "owner" },
        { text: "Action", value: "action" },
      ];
    },
  },
  methods: {},
};
</script>
<style scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
</style>
